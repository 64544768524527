<template>
  <div
    id="disable-right-click"
    class="detail-content margin-content"
    style="padding-left: 0"
    v-if="content && checkShow"
  >
    <div class="dc-header">
      <b-container fluid class="d-flex align-items-center font-title">
        <router-link
          :to="{ name: $route.params.shopId ? 'library' : 'library domain' }"
        >
          <div>コンテンツ</div>
        </router-link>
        <b-icon icon="chevronRight" class="mx-3"></b-icon>
        <router-link
          :to="{
            name: $route.params.shopId ? 'library' : 'library domain',
            params: { keyword: keywordFilter },
          }"
        >
          <div>絞り込み検索結果</div>
        </router-link>
        <b-icon icon="chevronRight" class="mx-3"></b-icon>
        <router-link :to="{ name: '' }">
          <div>目次</div>
        </router-link>
      </b-container>
    </div>
    <div class="dc-content">
      <b-container fluid>
        <b-row v-if="content && Object.keys(content).length > 0">
          <div class="ml-3 version-pc">
            <div
              class="dc-content-img"
              :class="{
                'no-img d-flex align-items-center justify-content-center':
                  !content.image_url,
              }"
            >
              <img
                v-if="content.image_url"
                :src="`${urlBackend}/storage/${content.image_url}`"
                alt="Image"
                class="card-img-top"
              />
              <div class="h5 text-dark" v-else>{{ content.title }}</div>
            </div>
            <div class="dc-content-detail d-flex flex-column">
              <div class="dc-content-detail-title mt-3">
                {{ content.title }}
              </div>
              <div
                class="dc-content-detail-author d-flex align-items-center mt-3"
              >
                <div class="img-author-library">
                  <img
                    :src="
                      content.avatar
                        ? `${urlBackend}/${content.avatar}`
                        : image_url_default
                    "
                    alt=""
                  />
                </div>
                <div class="nickname-author mx-3">
                  <router-link
                    :to="{
                      name: $route.params.shopId
                        ? 'author detail'
                        : 'author detail domain',
                      params: {
                        shopId: shop_id,
                        id: content.user_id,
                      },
                    }"
                  >
                    {{ content.last_name + " " + content.first_name }}
                  </router-link>
                </div>
              </div>
              <div class="dc-content-detail-category mt-3 mb-3">
                {{ content.category_name }}
              </div>
            </div>
          </div>
          <div class="ml-3 version-sp d-flex">
            <div
              class="dc-content-img"
              :class="{
                'no-img d-flex align-items-center justify-content-center':
                  !content.image_url,
              }"
            >
              <img
                v-if="content.image_url"
                :src="`${urlBackend}/storage/${content.image_url}`"
                alt="Image"
                class="card-img-top"
              />
              <div class="h5 text-dark" v-else>
                {{ content.title }}
              </div>
            </div>
            <div style="margin-left: 10px; width: 50%" class="pc-only">
              <div class="dc-content-detail-title mt-3">
                {{ content.title }}
              </div>
              <div>
                {{ content.description }}
              </div>
            </div>
          </div>

          <div style="margin-left: 20px; width: 50%" class="sp-only">
            <div class="dc-content-detail-title mt-3">
              {{ content.title }}
            </div>
            <div>
              {{ content.description }}
            </div>
          </div>
          <div class="mt-3 ml-3 version-sp">
            ご覧になりたい目次を以下から選択してください
          </div>
          <div class="content-width">
            <div class="dc-content-contents" style="padding-bottom: 20px">
              <div
                style="
                  margin-left: 40px;
                  border-top: 1px solid rgb(230, 230, 230);
                  border-bottom: 1px solid rgb(230, 230, 230);
                  width: 550px;
                "
              >
                <div
                  class="contents-list-item position-relative"
                  v-bind:class="{ onlyItem: dataCata.length < 2 }"
                  v-for="(item, index) in dataCata"
                  :key="index"
                >
                  <div class="" style="margin-left: 7px">
                    <div style="width: 70px"></div>
                    <div>
                      <div
                        v-if="item.title"
                        class="customTitleChapter"
                        v-bind:class="{ noBorderTop: index === 0 }"
                        style="margin-left: 70px"
                      >
                        <span>{{ item.title }}</span>
                      </div>
                      <div :class="`page-chaper-` + index" v-if="item.page">
                        <div
                          class="textName customTitlePage d-flex"
                          v-for="(ele, index) in item.page"
                          :key="index"
                          @click="
                            showDetailpage(
                              ele,
                              item.catalogue_id,
                              item.position
                            )
                          "
                        >
                          <div class="pageBorder">
                            <div v-if="ele.is_read" class="icon-check">
                              <b-icon icon="check"></b-icon>
                            </div>
                            <div v-else class="icon-check">
                              <img
                                class="icon-no-read"
                                :src="imgNoRead"
                                alt=""
                              />
                            </div>
                            <div style="padding-left: 60px">
                              {{ ele.page_title }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="hoverTittlePage" v-else>
                        <div
                          class="textName customTitlePage d-flex"
                          v-bind:class="{ onlyItem: dataCata.length < 2 }"
                          @click="showDetailpage(item, null, item.position)"
                        >
                          <div class="pageBorder">
                            <div v-if="item.is_read" class="icon-check">
                              <b-icon icon="check"></b-icon>
                            </div>
                            <div class="icon-check" v-else>
                              <img
                                class="icon-no-read"
                                :src="imgNoRead"
                                alt=""
                              />
                            </div>
                            <div style="">
                              {{ item.page_title }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    :style="
                      index === 0
                        ? { background: '#fff' }
                        : { background: '#d1d1d1' }
                    "
                    class="line-catalogue-above"
                  ></div>
                  <div
                    :style="
                      index === dataCata.length - 1
                        ? { background: '#fff' }
                        : { background: '#d1d1d1' }
                    "
                    class="line-catalogue-below"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <!-- <b-col cols="12">
            <div class="dc-content-description">
              {{ contentById.description }}
            </div>
          </b-col> -->
          <!-- <b-col cols="12" v-if="dataCata && dataCata.length > 0">
            <div class="dc-content-contents">
              <div class="contents-list">
                <div
                  class="contents-list-item"
                  v-for="(item, index) in dataCata"
                  :key="index"
                >
                  <router-link
                    :to="{
                      name: 'DetailPageLibrary',
                      params: {
                        idPage: item.pivot.catalogue_id,
                        idContent: item.pivot.content_id,
                      },
                    }"
                    class="d-flex align-items-center"
                  >
                    <div class="icon-check">
                      <b-icon icon="check"></b-icon>
                    </div>
                    <div class="textName">{{ item.title }}</div>
                  </router-link>
                </div>
              </div>
            </div>
          </b-col> -->
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
// import { mapActions } from "vuex";
// import { mapActions, mapGetters } from "vuex";
import imgDefault from "../../assets/img/author.jpg";
import image_url_default from "../../assets/img/content-default.png";
import imgNoRead from "../../assets/img/icon_text.png";
import { Constants } from "../../utils/constants";
import { Api } from "../../utils/http-common";
import { Urls } from "../../utils/urls";
export default {
  name: "DetailContentLibrary",
  data() {
    return {
      urlBackend: process.env.VUE_APP_ROOT_BACKEND,
      imgDefault,
      image_url_default,
      imgNoRead,
      dataCata: [],
      keywordFilter: this.$route.query.keyword,
      idContent: this.$route.params.idContent,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      content: null,
      checkShow: null,
    };
  },
  async created() {
    this.$store.commit("set", ["modalLoadingPage", true]);
    // const { params } = this.$route;
    // const reqData = {
    //   id: params.idContent,
    //   shop_id: this.shop_id,
    // };
    // this.getContentByIdUser(reqData);
    // this.getContentLibraryById(reqData);
    await this.contentById();
    const interval = setInterval(function () {
      if (document.getElementById("disable-right-click")) {
        document
          .getElementById("disable-right-click")
          .addEventListener("contextmenu", (event) => event.preventDefault());
        clearInterval(interval);
      }
    }, 50);
    document.addEventListener("contextmenu", (event) => event.preventDefault());
  },
  // watch: {
  // contentLibraryById() {
  //   if (this.contentLibraryById[0].url_redirect) {
  //     window.location.href = this.contentLibraryById[0].url_redirect;
  //     return;
  //   }
  //   if (this.contentLibraryById[0].catalogue) {
  //     this.contentLibraryById[0].catalogue.sort((a, b) =>
  //       a.position > b.position ? 1 : b.position > a.position ? -1 : 0
  //     );
  //   }
  //   this.dataCata = this.contentLibraryById[0].catalogue;
  // },
  // },
  // computed: {
  //   ...mapGetters(["contentLibraryById"]),
  // },
  methods: {
    // ...mapActions({ getContentByIdUser: "getContentByIdUser" }),
    // ...mapActions({ getContentLibraryById: "getContentLibraryById" }),
    async contentById() {
      const api = localStorage.getItem(Constants.TOKEN_USER)
        ? Api.userRequestServer
        : Api.adminRequestServer;
      await api
        .get(
          `/${Urls.CONTENT}/${this.$route.params.idContent}?shop_id=${this.shop_id}`
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            let dataDetail = data.data;
            if (dataDetail.url_redirect) {
              window.location.href = dataDetail.url_redirect;
              return;
            }
            if (dataDetail.is_page404 == true) {
              this.checkShow = false;
              this.$router.push({
                name: this.$route.params.shopId ? "page404" : "page404 domain",
                params: {
                  shopId: this.shop_id,
                },
              });
            } else this.checkShow = true;
            this.content = data.data;
            if (dataDetail.catalogue) {
              dataDetail.catalogue.sort((a, b) =>
                a.position > b.position ? 1 : b.position > a.position ? -1 : 0
              );
            }
            this.dataCata = dataDetail.catalogue;
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            console.log(error);
            localStorage.removeItem(Constants.TOKEN_USER);
            localStorage.removeItem(Constants.USER_TYPE_USER);
            localStorage.removeItem(Constants.EXPIRES_AT_USER);
            localStorage.removeItem(Constants.USER_ID);
            localStorage.removeItem(Constants.EMAIL_USER);
            localStorage.removeItem("popupSend");
            localStorage.removeItem(Constants.NORMAL_USER_INFO);
            localStorage.removeItem(Constants.IS_REMEMBER_USER);
            this.$router.push({
              name: this.$router.currentRoute.params.shopId
                ? "login shop"
                : "login shop domain",
            });
          } else {
            console.log(error);
          }
        });
      this.$store.commit("set", ["modalLoadingPage", false]);
    },
    showDetailpage(item, idChapter, position) {
      this.$router.push({
        name: this.$route.params.shopId
          ? "DetailPageLibrary"
          : "DetailPageLibrary domain",
        params: {
          idPage: item.id,
          idContent: this.$route.params.idContent,
        },
        query: {
          idChapter: idChapter,
          position: position,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-content {
  .dc-header {
    padding-top: 20px;
    font-size: 18px;
    a {
      color: #000;
      font-weight: 500;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .dc-content {
    position: relative;
    margin-top: 20px;
    &-img {
      width: 200px;
      height: 200px;
      @media (max-width: 449px) {
        width: 150px;
        height: 150px;
      }
      border: solid 1px black;
      position: relative;
      .text-dark {
        //position: absolute;
        //top: 50%;
        //left: 50%;
        //transform: translateX(-50%) translateY(-50%);
      }
    }
    &-detail {
      &-title {
        font-size: 20px;
        @media (max-width: 449px) {
          font-size: 14px;
        }
        font-weight: 600;
      }
      &-author {
        .img-author-library {
          width: 60px;
          height: 60px;
          border-radius: 100%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .nickname-author {
          a {
            color: black;
            font-size: 18px;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
      &-category {
        background: orange;
        width: fit-content;
        padding: 5px 10px;
        color: white;
        border: 2px solid saddlebrown;
      }
    }

    &-description {
      margin: 20px 0;
      font-size: 18px;
    }
    &-contents {
      .contents-title {
        font-size: 20px;
      }
      .contents-list {
        margin: 20px 0 50px 0px;
        padding: 20px 20px;
        background: #fff;
        &-item {
          padding: 15px 0;
          cursor: pointer;
          a {
            color: #000;
          }
          .icon-check {
            height: 25px;
            width: 25px;
            background: green;
            color: white;
            position: relative;
            border-radius: 50%;
            // margin: 0 auto;
            z-index: 4;
            svg {
              font-size: 18px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
          .textName {
            font-size: 17px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
.detail-content {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.line-catalogue-above {
  display: none;
  width: 2px;
  height: 100%;
  position: absolute;
  background-color: #d1d1d1;
  left: 34px;
  top: 0;
  z-index: 3;
}
.line-catalogue-below {
  display: none;
  width: 2px;
  height: 100%;
  position: absolute;
  background-color: #d1d1d1;
  left: 34px;
  top: 50%;
  z-index: 3;
}
.icon-no-read {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 4;
}
.dc-content-detail-title {
  margin-top: 1rem;
  @media (max-width: 449px) {
    margin-top: 0 !important;
  }
}
.customTitleChapter {
  /*width: 480px;*/
  height: 65px;
  display: block;
  text-decoration: none;
  font-size: 16px;
  padding: 20px 15px;
  font-weight: normal;
  border-top: 1px solid #e6e6e6;
  display: block;
  line-height: 24px;
  text-align: left;
  height: auto;
}
.customTitlePage {
  height: 65px;
  display: block;
  text-decoration: none;
  font-size: 16px;
  padding: 20px 15px;
  font-weight: normal;
  position: relative;
}
// .customTitlePage {
//   &::before {
//     content: "";
//     width: 2px;
//     height: 100%;
//     position: absolute;
//     background-color: #d1d1d1;
//     left: 52px;
//     top: 0;
//   }
// }
.hoverTittlePage :hover {
  background-color: #d1d1d1;
}
.customTitleChapter ::after {
  text-decoration-line: none;
}
.noBorderTop {
  border-top: none;
}

.contents-list-item:last-child {
  .customTitlePage:last-child {
    &::after {
      content: "";
      width: 2px;
      height: calc(50%);
      position: absolute;
      background-color: #d1d1d1;
      left: 34px;
      bottom: -2px;
      z-index: 3;
    }
  }
}
.contents-list-item:not(:first-child) {
  .customTitlePage {
    &::after {
      content: "";
      width: 2px;
      height: calc(100%);
      position: absolute;
      background-color: #d1d1d1;
      left: 34px;
      top: 2px;
      z-index: 3;
    }
  }
}
.customTitleChapter {
  position: relative;
}
.contents-list-item:first-child {
  .customTitleChapter {
    &::after {
      content: "";
      width: 2px;
      height: calc(50% + 4px);
      position: absolute;
      background-color: #d1d1d1;
      left: -36px;
      bottom: -2px;
      top: auto;
      z-index: 3;
    }
  }

  .customTitlePage:first-child {
    &::after {
      content: "";
      width: 2px;
      height: calc(50%);
      position: absolute;
      background-color: #d1d1d1;
      left: 34px;
      bottom: -2px;
      z-index: 3;
    }
  }

  .customTitlePage:last-child {
    &::after {
      content: "";
      width: 2px;
      height: calc(50%);
      position: absolute;
      background-color: #d1d1d1;
      left: 34px;
      bottom: -2px;
      z-index: 3;
    }
  }

  .page-chaper-0 {
    .customTitlePage {
      &::after {
        content: "";
        width: 2px;
        height: calc(100%);
        position: absolute;
        background-color: #d1d1d1;
        left: 34px;
        top: 2px;
        z-index: 3;
      }
    }
  }
  &.onlyItem {
    .page-chaper-0 {
      .customTitlePage {
        &::after {
          content: "";
          width: 2px;
          height: calc(100%);
          position: absolute;
          background-color: #d1d1d1;
          left: 34px;
          top: 2px;
          z-index: 3;
        }
      }
      .customTitlePage:last-child {
        &::after {
          content: "";
          width: 2px;
          height: calc(50%);
          position: absolute;
          background-color: #d1d1d1;
          left: 34px;
          top: 2px;
          z-index: 3;
        }
      }
    }
  }
}
.customTitleChapter:not(:last-child) {
  &::after {
    content: "";
    width: 2px;
    height: calc(100% + 2px);
    position: absolute;
    background-color: #d1d1d1;
    left: -36px;
    top: 0;
    z-index: 3;
  }
}
.customTitlePage.onlyItem {
  &::after {
    height: 0 !important;
  }
}
.textName.customTitlePage:not(:last-child),
.textName.customTitleChapter {
  &::after {
    content: "";
    width: 2px;
    height: calc(100%);
    position: absolute;
    background-color: #d1d1d1;
    left: 34px;
    top: 2px;
    z-index: 3;
  }
}
.page-chaper-0 {
  color: #000;
  .customTitlePage {
    &::after {
      content: "";
      width: 2px;
      height: calc(100%);
      position: absolute;
      background-color: #d1d1d1;
      left: 34px;
      top: 2px;
      z-index: 3;
    }
  }
}
// .customTitlePage:last-child {
//   &::after {
//     content: "";
//     width: 2px;
//     height: calc(50%);
//     position: absolute;
//     background-color: red !important;
//     left: 34px;
//     bottom: -2px;
//     z-index: 3;
//   }
// }

.page-chaper-0 {
  .pageBorder {
    border-top: 1px solid #e6e6e6 !important;
  }
}
</style>
